import React, { Suspense } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import Loading from './Loading';
import Layout from './Layout';

const MapList = React.lazy(() => import('./MapList'));
const MainMap = React.lazy(() => import('./map/MainMap'));
const AdminSection = React.lazy(() => import('./Admin/AdminSection'));
const ImportView = React.lazy(() => import('./Import/ImportView'));
const ErrorView = React.lazy(() => import('./ErrorView'));

const App = () => (
  <BrowserRouter>
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="maplist" element={<MapList />} />
          <Route path="map" element={<MainMap />} />
          <Route path="admin" element={<AdminSection />} />
          <Route path="import" element={<ImportView />} />
          <Route path="error" element={<ErrorView />} />
          <Route render={() => <Navigate to="/maplist" />} />
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
