import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const defaultState = {
  modal: {},
};

const middleware = [thunk];

let store;

if (process.env.NODE_ENV === 'production') {
  store = createStore(
    rootReducer,
    defaultState,
    applyMiddleware(...middleware),
  );
} else {
  store = createStore(
    rootReducer,
    defaultState,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f,
    ),
  );
}

export default store;
