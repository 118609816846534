import { setTemp } from '../actions/types';

export default function tempReducer(state = {}, { type, payload }) {
  switch (type) {
    case setTemp:
      return {
        ...state,
        [payload.key]: payload.val,
      };
    default:
      return state;
  }
}
