import {
  IMPORT_STARTED,
  PACKET_STARTED,
  PACKET_FINISHED,
  PACKET_FAILED,
} from '../actions/types';

const initialState = {
  inProgress: false,
  finished: false,
  type: null,
  packets: [],
  finishedPackets: [],
};

// if all packets are finished or failed, reset the state
function checkIfFinished(finishedPackets) {
  if (finishedPackets.every(({ finished, failed }) => finished || failed)) {
    const errors = finishedPackets.reduce((acc, { failed, error }, index) => {
      if (failed) acc.push({ index, error });

      return acc;
    }, []);

    const partialFailure = Boolean(
      errors.length && errors.length < finishedPackets.length,
    );

    return { finished: true, errors, partialFailure };
  }

  return null;
}

export default function importReducer(state = initialState, action) {
  const { finishedPackets } = state;
  let isFinished;

  switch (action.type) {
    case IMPORT_STARTED:
      return {
        inProgress: true,
        type: action.payload.type,
        packets: action.payload.packets,
        finishedPackets: action.payload.packets.map(() => ({
          started: false,
          finished: false,
          failed: false,
        })),
        options: action.payload.options,
      };
    case PACKET_STARTED:
      finishedPackets[action.payload].started = true;

      return {
        ...state,
        finishedPackets,
      };
    case PACKET_FINISHED:
      finishedPackets[action.payload].finished = true;

      // if all packets are finished or failed, reset the state
      isFinished = checkIfFinished(finishedPackets);
      if (isFinished) return { ...initialState, ...isFinished };

      return {
        ...state,
        finishedPackets,
      };
    case PACKET_FAILED:
      finishedPackets[action.payload.index].failed = true;
      finishedPackets[action.payload.index].error = action.payload.error;

      // if all packets are finished or failed, reset the state
      isFinished = checkIfFinished(finishedPackets);
      if (isFinished) return { ...initialState, ...isFinished };

      return {
        ...state,
        finishedPackets,
      };
    default:
      return state;
  }
}
