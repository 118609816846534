import { Auth } from 'aws-amplify';
import store from '../store';
import { setCurrentUser } from '../actions/userActions';

// prevent double executions from App.js and Router.js
let justChecked = false;

export default async function checkLogin(callback) {
  if (justChecked) {
    if (callback) callback();
    return;
  }
  justChecked = true;
  setTimeout(() => justChecked = false, 1000);

  try {
    const authenticatedUser = await Auth.currentAuthenticatedUser();

    // send the token along with all future requests
    const { accessToken } = authenticatedUser.signInUserSession;

    // log in on the front end
    const roles = accessToken.payload['cognito:groups'] || [];
    store.dispatch(setCurrentUser({ ...authenticatedUser.attributes, roles }));
  } finally {
    if (callback) callback();
  }
}
