import React, { useState, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import Axios from 'axios';
import Loading from './Loading';
import { setCurrentUser } from '../actions/userActions';
import {
  bypassLogin, appName, userPoolId, userPoolWebClientId,
} from '../constants';
import store from '../store';
import checkLogin from '../util/checkLogin';
import '@aws-amplify/ui-react/styles.css';

if (!bypassLogin) {
  Amplify.configure({
    Auth: {
      region: 'us-east-2',
      userPoolId,
      userPoolWebClientId,
      mandatorySignIn: true,
    },
  });
  Axios.interceptors.request.use(async (config) => {
    // eslint-disable-next-line
    if (!config.headers) config.headers = {};
    // eslint-disable-next-line
    if (!config.headers.common) config.headers.common = {};

    const authenticatedUser = await Auth.currentAuthenticatedUser();
    const { accessToken } = authenticatedUser.signInUserSession;
    // eslint-disable-next-line
    config.headers.common['Access-Token'] = accessToken.jwtToken;

    return config;
  });
}

const Navbar = React.lazy(() => import('./Navbar'));
const MapModelProvider = React.lazy(() => import('./MapModelProvider'));
const Modal = React.lazy(() => import('./Modal'));
const Login = React.lazy(() => import('./Login'));

function Layout({ user, setCurrentUser }) {
  const [checkedIfIsLoggedIn, setCheckedIfIsLoggedIn] = useState(null);
  const [showLogInForm, setShowLogInForm] = useState(false);

  useEffect(() => {
    if (bypassLogin) {
      store.dispatch(setCurrentUser({ name: 'Guest', roles: ['admin'] }));
      setCheckedIfIsLoggedIn(true);
    } else checkLogin(() => setCheckedIfIsLoggedIn(true));

    document.title = appName;
  }, [setCurrentUser]);

  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        {checkedIfIsLoggedIn
          ? (user.name || showLogInForm)
            ? (
              <Authenticator>
                {() => (
                  <MapModelProvider>
                    <Navbar onLogout={() => setShowLogInForm(false)} />
                    <Suspense fallback={<Loading />}>
                      <div className="RouterSwitch">
                        <Outlet />
                      </div>
                      <Modal />
                    </Suspense>
                  </MapModelProvider>
                )}
              </Authenticator>
            )
            : <Login onLoginClick={() => setShowLogInForm(true)} />
          : <Loading />}
      </Suspense>
    </div>
  );
}

export default connect(({ user }) => ({ user }), { setCurrentUser })(Layout);
