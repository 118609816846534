import { openModal, closeModal } from '../actions/types';

export default function modalReducer(state = {}, { type, payload }) {
  switch (type) {
    case openModal:
      return {
        isOpen: true,
        ...payload,
      };
    case closeModal:
      return {
        isOpen: false,
      };
    default:
      return state;
  }
}
