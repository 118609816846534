export const setCurrentUser = 'SET_CURRENT_USER';
export const openModal = 'OPEN_MODAL';
export const closeModal = 'CLOSE_MODAL';
export const setLayers = 'SET_LAYERS';
export const toggleLayer = 'TOGGLE_LAYER';
export const setLayerFilter = 'SET_LAYER_FILTER';
export const setTemp = 'SET_TEMP';

// imports
export const IMPORT_STARTED = 'IMPORT_STARTED';
export const PACKET_STARTED = 'PACKET_STARTED';
export const PACKET_FINISHED = 'PACKET_FINISHED';
export const PACKET_FAILED = 'PACKET_FAILED';
