import React, { useState, useEffect } from 'react';
import { MDBSpinner } from 'mdbreact';
import '../assets/stylesheets/Loading.sass';

const texts = [
  'Loading your data',
  'This should only take a few seconds',
  'Almost there',
];

const Loading = ({ dontShowText }) => {
  const [textIndex, setTextIndex] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTextIndex(0);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((currentIndex) => (
        currentIndex >= texts.length - 1 ? 0 : currentIndex + 1
      ));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="Loading">
      <div>
        <div className="d-flex justify-content-center my-3">
          <MDBSpinner big multicolor />
        </div>
        {!dontShowText && textIndex !== null && (
          <h4>{texts[textIndex]}...</h4>
        )}
      </div>
    </div>
  );
};

export default Loading;
