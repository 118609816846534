export const apiUrl = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development'
  ? process.env.apiRootURL
  : 'http://localhost:4000';

export const bypassLogin = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_BYPASS_LOGIN === 'true'
  : false;

export const appName = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_APP_NAME
  : 'Fort Morgan GIS';

export const userPoolId = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_USER_POOL_ID
  : 'us-east-2_0tMXhjRla';

export const userPoolWebClientId = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  : 'v22t29oiqth88g00n6fcvkr12';
