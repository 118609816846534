import { setLayers, toggleLayer, setLayerFilter } from '../actions/types';

function toggle(newState, layer) {
  const layerState = newState[layer] || {};
  layerState.shown = !layerState.shown;
  // eslint-disable-next-line no-param-reassign
  newState[layer] = layerState;
}

export default function layersReducer(state = {}, { type, payload }) {
  let newState;
  switch (type) {
    case setLayers:
      return payload;
    case toggleLayer:
      newState = { ...state };
      if (Array.isArray(payload)) {
        payload.forEach((layer) => toggle(newState, layer));
      } else toggle(newState, payload);

      return newState;
    case setLayerFilter:
      newState = { ...state };
      // eslint-disable-next-line no-case-declarations
      const layerState = newState[payload.layer] || {};
      // eslint-disable-next-line no-case-declarations
      const filtersState = layerState.filters || {};
      filtersState[payload.filterKey] = payload.newFilter;

      return newState;
    default:
      return state;
  }
}
