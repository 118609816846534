import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import Axios from 'axios';
import * as Sentry from '@sentry/react';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { apiUrl } from './constants';
import store from './store';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './assets/stylesheets/index.sass';

Axios.defaults.baseURL = apiUrl;
// Axios.defaults.baseURL = 'https://prd-fortmorganapi.long103.com';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://0d81f84b4e6c410b9b49c200d3f5d9a4@o429864.ingest.sentry.io/5378878',
  });
}

ReactDOM.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>,
  document.getElementById('root'),
);

serviceWorker.register();
