import { setCurrentUser } from '../actions/types';

const defaultState = { roles: [] };

export default function userReducer(state = defaultState, { type, payload }) {
  switch (type) {
    case setCurrentUser:
      return payload || defaultState;
    default:
      return state;
  }
}
