import { combineReducers } from 'redux';
import importReducer from './import';
import modal from './modal';
import layers from './layers';
import temp from './temp';
import user from './user';

export default combineReducers({
  import: importReducer,
  modal,
  layers,
  temp,
  user,
});
